import { defineStore } from 'pinia';
import {
	useAddProductsToWishlist,
	useUserWishlists,
	type Wishlist,
	type WishlistProduct,
} from '~/data/wishlist';
import {
	useAddProductsToWishlist as useAddProductsToWishlistV2,
	useUserWishlists as useUserWishlistsV2,
	type Wishlist as WishlistV2,
} from '~/data/wishlist-v2';
import { storeToRefs } from 'pinia';
import { useLoganStore } from './logan';

export const useWishlistStore = defineStore('wishlist', () => {
	const { isWishlistV2Enabled } = useStoreAddons();
	const { loganId } = storeToRefs(useLoganStore());
	const { wishlistsResponse, areWishlistsLoading } = useUserWishlists(loganId);
	const { addProductsToWishlist } = useAddProductsToWishlist();
	const favouriteProductsQueue = ref<number[]>([]);

	const wishlists = computed(() => {
		if (!wishlistsResponse.value || !isWishlistV2Enabled.value) {
			return null;
		}
		let favouriteList: Wishlist | null = null;
		const otherLists: Wishlist[] = [];
		for (const list of wishlistsResponse.value) {
			if (list.name !== 'Favourites') {
				otherLists.push(list);
			} else {
				favouriteList = list;
			}
		}
		if (!favouriteList) {
			return null;
		}
		return {
			favouriteList,
			otherLists,
		};
	});

	const favouritesMap = computed(() => {
		const set = new Set<string>();
		if (!wishlists.value?.favouriteList || !isWishlistV2Enabled.value) {
			return set;
		}
		const {
			products,
		}: {
			products: {
				[key: string]: WishlistProduct;
			};
		} = wishlists.value.favouriteList;

		for (const [productId] of Object.entries(products)) {
			set.add(productId);
		}
		return set;
	});

	// handle delayed adding of products to wishlist
	watch(wishlists, (lists) => {
		if (
			lists &&
			favouriteProductsQueue.value.length > 0 &&
			!isWishlistV2Enabled.value
		) {
			const favListId = lists.favouriteList.id;
			addProductsToWishlist({
				logan_id: loganId.value,
				product_shopify_ids: favouriteProductsQueue.value,
				wishlist_id: favListId,
			});
			favouriteProductsQueue.value = [];
		}
	});

	const { userWishlists, areWishlistsLoading: areWishlistsLoadingV2 } =
		useUserWishlistsV2(loganId);
	const { addProductsToWishlist: addProductsToWishlistV2 } =
		useAddProductsToWishlistV2();

	const wishlistsV2 = computed(() => {
		if (!userWishlists.value || isWishlistV2Enabled.value === false) {
			return null;
		}
		let favouriteList: WishlistV2 | null = null;
		const otherLists: WishlistV2[] = [];
		for (const list of userWishlists.value) {
			if (list.name !== 'Favourites') {
				otherLists.push(list);
			} else {
				favouriteList = list;
			}
		}
		if (!favouriteList) {
			return null;
		}
		return {
			favouriteList,
			otherLists,
		};
	});

	watch(
		wishlistsV2,
		(lists) => {
			if (
				lists &&
				favouriteProductsQueue.value.length > 0 &&
				isWishlistV2Enabled.value
			) {
				const favListId = lists.favouriteList.id;
				addProductsToWishlistV2({
					id: favListId,
					products: favouriteProductsQueue.value,
				});
				favouriteProductsQueue.value = [];
			}
		},
		{ immediate: true },
	);

	const favouritesMapV2 = computed(() => {
		const set = new Set<number>();
		if (!wishlistsV2.value?.favouriteList) {
			return set;
		}
		const productIds = wishlistsV2.value.favouriteList.products;
		for (const productId of productIds) {
			set.add(productId);
		}
		return set;
	});

	return {
		wishlists,
		areWishlistsLoading,
		favouritesMap,
		favouriteProductsQueue,

		favouritesMapV2,
		wishlistsV2,
		areWishlistsLoadingV2,
		addProductsToWishlistV2,
	};
});

export const useWishlistTestStore = defineStore('wishlist', () => {
	const favouriteProductsQueue = ref<number[]>([]);

	const wishlists = computed(() => {
		return null;
	});

	const favouritesMap = computed(() => {
		const set = new Set<string>();
		return set;
	});

	// handle delayed adding of products to wishlist
	watch(wishlists, (lists) => {
		if (lists) {
			favouriteProductsQueue.value = [];
		}
	});

	return {
		wishlists,
		areWishlistsLoading: ref(false),
		favouritesMap,
		favouriteProductsQueue,
	};
});
