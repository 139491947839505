import { Type, type Static } from '@sinclair/typebox';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { validatorFactory, validatorFactoryArray } from '@laam/lib/validator';
import * as Sentry from '@sentry/vue';
import { useGlobalStore } from '~/stores/global';
import {
	productsResponseSchema,
	type ProductsResponseSchema,
} from '@laam/cms-shared';

const wishlistSchema = Type.Object({
	id: Type.Number(),
	name: Type.String(),
	handle: Type.String(),
	deleted: Type.Boolean(),
	allow_gifting: Type.Boolean(),
	public: Type.Boolean(),
	created_at: Type.String(),
	products: Type.Array(Type.Integer()),
	total_products_count: Type.Number(),
	logan_id: Type.Number(),
});

export type Wishlist = Static<typeof wishlistSchema>;

export interface AddToWishlistPayload {
	product_shopify_ids: number[];
}
interface EditWishlistPayload {
	name: string;
	allow_gifting: boolean;
	is_public: boolean;
}

interface CreateWishlistPayload {
	name: string;
	allow_gifting: boolean;
	is_public: boolean;
}

const userWishlistsValidator = validatorFactoryArray<Wishlist[]>(
	Type.Array(wishlistSchema),
);

const wishlistValidator = validatorFactory<Wishlist>(wishlistSchema);

const wishlistProductsValidator = validatorFactory<ProductsResponseSchema>(
	productsResponseSchema,
);

export const createDefaultWishlist = async () => {
	const api = createApiInstance();
	const response = await api.post('/v1/wishlist/default');
	if (response.status > 209) {
		throw new Error('Failed to create default wishlist');
	}
	try {
		return wishlistValidator.verify(response.data);
	} catch (error) {
		const err = new Error('Failed to verify default wishlist data');
		console.error('Failed to verify default wishlist data', error);
		Sentry.captureException(err, (scope) => {
			scope.setContext('errors', {
				error: err,
			});
			return scope;
		});
		throw err;
	}
};

export const getUserWishlists = async () => {
	const api = createApiInstance();
	const response = await api.get('/v1/wishlist');
	if (response.status > 209) {
		throw new Error('Failed to fetch wishlist');
	}
	try {
		const body = userWishlistsValidator.verify(response.data);
		if (body.length) {
			// find the default wishlist
			const favouriteList = body.find(
				(wishlist) => wishlist.name === 'Favourites',
			);
			if (favouriteList) {
				return body;
			}
			// create default wishlist and append to the lists
			const defaultWishlist = await createDefaultWishlist();
			return [...body, defaultWishlist];
		}
		// we need to create a default wishlist if the user has no wishlist
		const defaultWishlist = await createDefaultWishlist();
		return [defaultWishlist];
	} catch (error) {
		const err = new Error('Failed to verify user wishlist data');
		console.error('Failed to verify user wishlist data', error);
		Sentry.captureException(err, (scope) => {
			scope.setContext('errors', {
				error: err,
			});
			return scope;
		});
		throw err;
	}
};

export const getWishlistByHandle = async (handle: string) => {
	const api = createApiInstance();
	const response = await api.get('/v1/wishlist/' + handle);
	if (response.status > 209) {
		const error = new Error('Failed to fetch wishlist');
		Sentry.captureException(error, (scope) => {
			scope.setContext('response', response.data);
			return scope;
		});
		throw error;
	}
	try {
		return wishlistValidator.verify(response.data);
	} catch (error) {
		const err = new Error('Failed to verify wishlist data');
		console.error('Failed to verify wishlist data', error);
		Sentry.captureException(err, (scope) => {
			scope.setContext('errors', {
				error: err,
			});
			return scope;
		});
		throw err;
	}
};

export const getWishlistProducts = async (
	handle: string,
	cursor?: string,
	limit?: number,
): Promise<ProductsResponseSchema> => {
	const api = createApiInstance();
	const response = await api.get('/v1/wishlist/' + handle + '/products', {
		params: {
			...(cursor && { cursor }),
			...(limit && { limit }),
		},
	});
	if (response.status > 209) {
		const error = new Error('Failed to fetch wishlist');
		Sentry.captureException(error, (scope) => {
			scope.setContext('response', response.data);
			return scope;
		});
		throw error;
	}
	try {
		return wishlistProductsValidator.verify(response.data);
	} catch (error) {
		const err = new Error('Failed to verify wishlist data');
		console.error('Failed to verify wishlist data', error);
		Sentry.captureException(err, (scope) => {
			scope.setContext('errors', {
				error: err,
			});
			return scope;
		});
		throw err;
	}
};

export const removeFromWishlist = async (
	products: number[],
	wishlistId: number,
) => {
	const api = createApiInstance();
	const response = await api.delete(
		'/v1/wishlist/' + wishlistId + '/products',
		{
			data: {
				product_shopify_ids: products,
			},
		},
	);
	if (response.status > 299) {
		const error = new Error('Failed to remove products from wishlist');
		Sentry.captureException(error, (scope) => {
			scope.setContext('response', response.data);
			scope.setContext('payload', {
				products,
				wishlistId,
			});
			return scope;
		});
		throw error;
	}
	return;
};

const editWishlist = async (id: number, payload: EditWishlistPayload) => {
	const api = createApiInstance();
	const response = await api.patch('/v1/wishlist/' + id, payload);
	if (response.status > 209) {
		const error = new Error('Failed to edit wishlist');
		Sentry.captureException(error, (scope) => {
			scope.setContext('response', response.data);
			scope.setContext('payload', { ...payload });
			return scope;
		});
		throw error;
	}
	return response.data as Wishlist;
};

const createWishlist = async (payload: CreateWishlistPayload) => {
	const api = createApiInstance();
	const response = await api.post('/v1/wishlist', payload);
	if (response.status !== 200) {
		const error = new Error('Failed to create wishlist');
		Sentry.captureException(error, (scope) => {
			scope.setContext('response', response.data);
			scope.setContext('payload', { ...payload });
			return scope;
		});
		throw error;
	}
	return;
};

export const deleteWishlist = async (id: number) => {
	const api = createApiInstance();
	const response = await api.delete('/v1/wishlist/' + id);
	if (response.status > 299) {
		const error = new Error('Failed to delete wishlist');
		Sentry.captureException(error, (scope) => {
			scope.setContext('response', response.data);
			scope.setContext('payload', { id });
			return scope;
		});
		throw error;
	}
	return;
};

const addProductsToWishlist = async (id: number, products: number[]) => {
	const api = createApiInstance();
	const response = await api.post('/v1/wishlist/' + id + '/products', {
		product_shopify_ids: products,
	});
	if (response.status > 209) {
		const error = new Error('Failed to add products to wishlist');
		Sentry.captureException(error, (scope) => {
			scope.setContext('response', response.data);
			scope.setContext('payload', { id, products });
			return scope;
		});
		throw error;
	}
	return response.data;
};

export const useUserWishlists = (loganId: Ref<number>) => {
	const { storeType } = useGlobalStore();
	const isEnabled = computed(() => !!loganId.value && storeType !== 'OCTANE');
	const { data, isLoading, suspense } = useQuery<Wishlist[]>({
		queryKey: ['user-wishlists-v2', loganId],
		queryFn: async () => getUserWishlists(),
		enabled: isEnabled,
	});
	return { userWishlists: data, areWishlistsLoading: isLoading, suspense };
};

export const useWishlistByHandle = (handle: Ref<string>) => {
	const isEnabled = computed(() => !!handle.value);

	const { data, suspense, error } = useQuery({
		queryKey: ['wishlist-v2-detail-server', handle],
		queryFn: async () => getWishlistByHandle(handle.value),
		staleTime: Infinity,
		enabled: isEnabled,
	});
	return { data, suspense, error };
};

export const useRemoveFromWishlist = () => {
	interface RemoveFromWishlistParams {
		products: number[];
		wishlist: number;
	}
	const { loganId, isSignedIn } = useLoganStore();
	const queryClient = useQueryClient();
	const { mutate, isPending, isSuccess } = useMutation({
		mutationFn: ({ products, wishlist }: RemoveFromWishlistParams) =>
			removeFromWishlist(products, wishlist),
		onSuccess: async (_, variables) => {
			try {
				queryClient.invalidateQueries({
					queryKey: ['user-wishlists-v2', loganId],
				});
				queryClient.invalidateQueries({
					queryKey: [['wishlist-v2', variables.wishlist], isSignedIn],
				});
			} catch (error) {
				console.error('Error refetching wishlist', error);
			}
		},
	});

	return {
		removeFromWishlist: (params: RemoveFromWishlistParams) => mutate(params),
		isPending,
		isSuccess,
	};
};

export const useEditWishlist = () => {
	interface EditWishlistParams {
		id: number;
		payload: EditWishlistPayload;
	}
	const queryClient = useQueryClient();
	const { loganId } = useLoganStore();
	const { mutate, isPending, isSuccess } = useMutation<
		Wishlist,
		unknown,
		EditWishlistParams
	>({
		mutationKey: ['edit-wishlist'],
		mutationFn: (params: EditWishlistParams) =>
			editWishlist(params.id, params.payload),
		onSuccess: async (data) => {
			try {
				queryClient.invalidateQueries({
					queryKey: ['user-wishlists-v2', loganId],
				});
				queryClient.invalidateQueries({
					queryKey: ['wishlist-detail-server', data.handle],
				});
			} catch (error) {
				console.error('Error refetching wishlist', error);
			}
		},
	});

	return {
		editWishlist: (params: EditWishlistParams) => mutate(params),
		isPending,
		isSuccess,
	};
};

export const useCreateWishlist = () => {
	const queryClient = useQueryClient();
	const { loganId } = useLoganStore();
	const { mutate, isPending, isSuccess } = useMutation({
		mutationKey: ['create-wishlist'],
		mutationFn: (payload: CreateWishlistPayload) => createWishlist(payload),
		onSuccess: async () => {
			try {
				// refetch user wishlists
				await queryClient.invalidateQueries({
					queryKey: ['user-wishlists-v2', loganId],
				});
			} catch (error) {
				console.error('Error refetching wishlist', error);
			}
		},
	});

	return {
		createWishlist: (payload: CreateWishlistPayload) => mutate(payload),
		isPending,
		isSuccess,
	};
};

export const useDeleteWishlist = () => {
	const queryClient = useQueryClient();
	const { loganId } = useLoganStore();
	const { mutate, isPending, isSuccess } = useMutation({
		mutationKey: ['delete-wishlist'],
		mutationFn: (id: number) => deleteWishlist(id),
		onSuccess: async () => {
			try {
				queryClient.invalidateQueries({
					queryKey: ['user-wishlists-v2', loganId],
				});
			} catch (error) {
				console.error('Error refetching wishlist', error);
			}
		},
	});

	return {
		deleteWishlist: (payload: number) => mutate(payload),
		isPending,
		isSuccess,
	};
};

export const useAddProductsToWishlist = () => {
	interface AddProductsToWishlistParams {
		id: number;
		products: number[];
	}
	const queryClient = useQueryClient();
	const { loganId, isSignedIn } = useLoganStore();
	const { mutate, isPending } = useMutation({
		mutationKey: ['add-products-to-wishlist'],
		mutationFn: (payload: AddProductsToWishlistParams) =>
			addProductsToWishlist(payload.id, payload.products),
		onSuccess: async (_, variables) => {
			try {
				queryClient.invalidateQueries({
					queryKey: ['user-wishlists-v2', loganId],
				});
				queryClient.invalidateQueries({
					queryKey: [['wishlist-v2', variables.id], isSignedIn],
				});
			} catch (error) {
				console.error('Error refetching wishlist', error);
			}
		},
	});
	return {
		addProductsToWishlist: (payload: AddProductsToWishlistParams) =>
			mutate(payload),
		isPending,
	};
};
