export const useStoreAddons = () => {
	const store = useGlobalStore();
	const { addOns } = storeToRefs(store);

	const isWishlistV2Enabled = computed(() => {
		return addOns.value?.wishlist_v2?.enable ?? false;
	});

	return {
		isWishlistV2Enabled,
	};
};
